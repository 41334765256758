import { createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../consts";
import moment from "moment";

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    getEvents(state, action) {
      const events = action.payload;
      for (let i = 0; i < events.length; i++) {
        events[i].start = moment
          .utc(events[i].startevent)
          .local()
          .format(config.PGTimeFormat);
        events[i].end = moment
          .utc(events[i].endevent)
          .local()
          .format(config.PGTimeFormat);
      }

      state.events = events;
    },
    createEvent(state, action) {
      let event = action.payload;
      event.start = moment
        .utc(event.startevent)
        .local()
        .format(config.PGTimeFormat);
      event.end = moment
        .utc(event.endevent)
        .local()
        .format(config.PGTimeFormat);
      state.events.push(event);
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      let event = { ...action.payload };

      event.start = moment
        .utc(event.startevent)
        .local()
        .format(config.PGTimeFormat);
      event.end = moment
        .utc(event.endevent)
        .local()
        .format(config.PGTimeFormat);

      state.events = state.events.map((_event) => {
        if (Number(_event.id) === Number(event.id)) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = [
        ...state.events.filter(
          (_event) => Number(_event.id) !== Number(eventId),
        ),
      ];
    },
    selectRange(state, action) {
      const { startevent, endevent } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        startevent,
        endevent,
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

export const { reducer } = slice;

export const getEvents = () => async (dispatch) => {
  const { data } = await axios.get(`${config.api}/calendar/events`);

  dispatch(slice.actions.getEvents(data));
};

export const createEvent = (data) => async (dispatch) => {
  let startevent = moment(data.start)
    .startOf("day")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss");

  let endevent = moment(data.end)
    .endOf("day")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss");

  const { data: newEvent } = await axios.post(`${config.api}/calendar/events`, {
    ...data,
    startevent,
    endevent,
  });

  dispatch(slice.actions.createEvent(newEvent));
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update) => async (dispatch) => {
  let startevent = moment(update.start)
    .startOf("day")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss");
  let endevent = moment(update.end)
    .startOf("day")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss");

  const { data } = await axios.patch(
    `${config.api}/calendar/events/${eventId}`,
    { ...update, startevent: startevent, endevent: endevent },
  );

  dispatch(slice.actions.updateEvent(data));
};

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    await axios.delete(`${config.api}/calendar/events/${eventId}`);

    dispatch(slice.actions.deleteEvent({ eventId }));
  } catch (err) {
    console.log(err);
  }
};

export const selectRange = (startevent, endevent) => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      startevent: moment(startevent).valueOf(),
      endevent: moment(endevent).subtract(1, "minute").valueOf(),
    }),
  );
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
