import React, { useState } from "react";

import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  TextField,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Grid,
} from "@mui/material";
import axios from "axios";

import config from "../../consts";

import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import PasswordInput from "@rlmonger/web_components/dist/Inputs/Password";
import { useDispatch } from "../../store";
import TOTPEntryDialog from "./TOTPEntryDialog";

const LoginJWT = (props) => {
  const isMountedRef = useIsMountedRef();
  const { login, twoFactorLogin } = useAuth();

  const [forgotEmail, setForgotEmail] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [twoFactorUserID, setTwoFactorUserID] = useState(null);
  const [OTPOpen, setOTPOpen] = useState(false);
  const [OTPError, setOTPError] = useState(null);
  const [OTPLoading, setOTPLoading] = useState(false);

  const handleOTPSubmit = async (otp) => {
    setOTPLoading(true);
    setOTPError(null);
    const { data } = await axios.post(`${config.api}/auth/2fa-verify`, {
      otp,
      user_id: twoFactorUserID,
    });

    if (data.error) {
      setOTPLoading(false);
      setOTPError(data.error);
      return;
    }

    let { token, user } = data;

    twoFactorLogin(token, user);

    setTwoFactorUserID(null);
    setOTPLoading(false);
    handleOTPClose();
  };

  const handleOTPOpen = () => {
    setOTPOpen(true);
  };

  const handleOTPClose = () => {
    setOTPOpen(false);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getRecoveryEmail = async () => {
    setLoading(true);
    const { status } = await axios.post(`${config.api}/auth/forgotPassword`, {
      email: forgotEmail,
      origin: 0, // CIG origin
    });
    if (status == 200) {
      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        toggleModal();
      }, [2000]);
    }
    if (status != 200) {
      setSuccess(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const { user_id, code } = await login(
              values.email,
              values.password,
            );

            if (code === 2) {
              setTwoFactorUserID(user_id);
              handleOTPOpen();
            }
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            style={{ width: `100%` }}
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              autoFocus
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <PasswordInput
              formControlStyle={{ width: `100%` }}
              style={{ width: `100%` }}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  setForgotEmail("");

                  toggleModal();
                }}
              >
                Forgot Password
              </Button>
            </Box>
            {false && (
              <Box sx={{ mt: 2 }}>
                <Alert severity="info">
                  <div>
                    Use <b>demo@monger.tech</b> and password <b>monger</b>
                  </div>
                </Alert>
              </Box>
            )}
          </form>
        )}
      </Formik>
      <Dialog open={modalOpen} onClose={toggleModal} toggle={toggleModal}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your email below to recieve a link to reset your password.
          </DialogContentText>
          <TextField
            sx={{ mt: 2 }}
            label="Email"
            onChange={(e) => setForgotEmail(e.target.value)}
            value={forgotEmail}
            type="email"
            fullWidth="true"
          />
        </DialogContent>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <DialogActions>
          <Button onClick={toggleModal}>Cancel</Button>
          <Button
            onClick={() => {
              getRecoveryEmail();
            }}
          >
            Recover Password
          </Button>
        </DialogActions>
      </Dialog>
      <TOTPEntryDialog
        loading={OTPLoading}
        error={OTPError}
        open={OTPOpen}
        onClose={handleOTPClose}
        onSubmit={handleOTPSubmit}
      />
    </>
  );
};

export default LoginJWT;
