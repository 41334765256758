import { createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../consts";
import moment from "moment";
import colors from "../colors";

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  group: null,
  isLoading: false,
};

const slice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    getEvents(state, action) {
      const events = action.payload;
      for (let i = 0; i < events.length; i++) {
        events[i].start = moment
          .utc(events[i].leave_start_date)
          .local()
          .format(config.PGTimeFormat);
        events[i].end = moment
          .utc(events[i].leave_end_date)
          .local()
          .format(config.PGTimeFormat);

        events[
          i
        ].title = `${events[i].accounts.first_name} ${events[i].accounts.last_name} ${events[i].type_of_leave}`;

        events[i].allDay = true;
        events[i].display = "auto";

        let backgroundColor = "#FF0000";
        if (events[i].leave_approval.length >= 1) {
          backgroundColor = colors.mainGreen;
        } else {
          backgroundColor = colors.grey;
        }

        events[i].backgroundColor = backgroundColor;
        events[i].borderColor = backgroundColor;
      }

      state.events = events;
    },

    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },

    selectGroup(state, action) {
      const { compgroups_id = null } = action.payload;

      state.group = compgroups_id;
    },

    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
    },
  },
});

export const { reducer } = slice;

export const getEvents = (payroll_group_id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));

    const { data } = await axios.get(`${config.api}/v2/timesheet/leave`, {
      params: {
        admin: "true",
        payroll_group_id: payroll_group_id ?? undefined,
      },
    });

    console.log(data);

    dispatch(slice.actions.getEvents(data));
  } catch (error) {
    console.error("Error fetching events:", error);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const selectGroup = (compgroups_id) => async (dispatch) => {
  dispatch(slice.actions.selectGroup({ compgroups_id }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
