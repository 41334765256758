import { combineReducers } from "@reduxjs/toolkit";

import { reducer as user } from "../slices/user";
import { reducer as calendarReducer } from "../slices/calendar";
import { reducer as leaveCalendarReducer } from "../slices/leaveCalendar";

const rootReducer = combineReducers({
  user: user,
  calendar: calendarReducer,
  leave: leaveCalendarReducer,
});

export default rootReducer;
