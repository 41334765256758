import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import Login from "../pages/Login";
import Deactivated from "../pages/Deactivated";
import RecoverAccount from "../pages/RecoverPassword";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const { user } = auth;

  const location = useLocation();
  const navigate = useNavigate();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    if (requestedLocation === "/recoverAccount") {
      return <RecoverAccount />;
    }
    if (requestedLocation === "/privacy-policy") {
      return <PrivacyPolicy />;
    } else {
      return <Login />;
    }
  }

  if (user?.active === false) {
    return <Deactivated />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    navigate(requestedLocation);
    return <></>;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
