import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import { verify, JWT_SECRET } from "../utils/jwt";
import config from "../consts";
import { User } from "../api/typescript/UserApi";

interface State {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
  token: string | null;
}

enum Action {
  INITIALISE = "INITIALIZE",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  REGISTER = "REGISTER",
}

interface InitialiseAction {
  type: Action.INITIALISE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    token: string | null;
  };
}

interface LoginAction {
  type: Action.LOGIN;
  payload: {
    user: User;
    token: string;
  };
}

interface LogoutAction {
  type: Action.LOGOUT;
}

interface RegisterAction {
  type: Action.REGISTER;
  payload: {
    user: User;
    token: string;
  };
}

type AuthAction =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

type AccessToken = string | null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null,
};

const setSession = (accessToken: AccessToken) => {
  if (accessToken) {
    localStorage.setItem("authToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("authToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state: State, action: InitialiseAction) => {
    const { isAuthenticated, user, token } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token,
    };
  },
  LOGIN: (state: State, action: LoginAction) => {
    const { user, token } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      token,
    };
  },
  LOGOUT: (state: State) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    token: null,
  }),
  REGISTER: (state: State, action: RegisterAction) => {
    const { user, token } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      token,
    };
  },
};

const reducer = (state: State, action: AuthAction) => {
  switch (action.type) {
    case Action.INITIALISE:
      return handlers.INITIALIZE(state, action);
    case Action.LOGIN:
      return handlers.LOGIN(state, action);
    case Action.LOGOUT:
      return handlers.LOGOUT(state);
    case Action.REGISTER:
      return handlers.REGISTER(state, action);
  }
};

interface JWTContext extends State {
  platform: string;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  twoFactorLogin: (token: string, user: any) => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
}

const AuthContext = createContext<JWTContext>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  twoFactorLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("authToken");

        if (accessToken && verify(accessToken, JWT_SECRET)) {
          await setSession(accessToken);

          const response = await axios.get(`${config.api}/user/profile`);

          let user = response.data;

          const { data: permissionData } = await axios.post(
            `${config.api}/user/getpermissions`,
          );

          user.permissions = permissionData;
          try {
            const { data: photoData } = await axios.post(
              `${config.api}/timesheet/getLastPhoto`,
              {
                username_id: user.username_id,
              },
            );

            user.permissions = permissionData;
            try {
              const { data: photoData } = await axios.post(
                `${config.api}/timesheet/getLastPhoto`,
                {
                  username_id: user.username_id,
                },
              );

              user.avatar = photoData[0].photo;
            } catch (err) {
              
            }

            dispatch({
              type: Action.INITIALISE,
              payload: {
                isAuthenticated: true,
                user,
                token: accessToken,
              },
            });
          } catch {
            dispatch({
              type: Action.INITIALISE,
              payload: {
                isAuthenticated: false,
                user: null,
                token: null,
              },
            });
          }

          dispatch({
            type: Action.INITIALISE,
            payload: {
              isAuthenticated: true,
              user,
              token: accessToken,
            },
          });
        } else {
          dispatch({
            type: Action.INITIALISE,
            payload: {
              isAuthenticated: false,
              user: null,
              token: null,
            },
          });
        }
      } catch (err) {
        
        dispatch({
          type: Action.INITIALISE,
          payload: {
            isAuthenticated: false,
            user: null,
            token: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const { data, status } = await axios.post(`${config.api}/auth/login/`, {
      username: email,
      password,
    });

    
    const { code } = data;
    if (code === 2) {
      // Proceed with 2 Factor
      
      return data;
    }

    let { token: accessToken, user } = data;

    if (!user && status === 200)
      throw { message: "The username or password is incorrect." };

    setSession(accessToken);

    const { data: permissionData } = await axios.post(
      `${config.api}/user/getpermissions`,
    );

    user.permissions = permissionData;

    try {
      const { data: photoData } = await axios.post(
        `${config.api}/timesheet/getLastPhoto`,
        {
          username_id: user.username_id,
        },
      );

      user.permissions = permissionData;

      dispatch({
        type: Action.LOGIN,
        payload: {
          user,
          token: accessToken,
        },
      });
    } catch (err) {
      
    }
  };

  const twoFactorLogin = async (token: string, user: any) => {
    setSession(token);

    const { data: permissionData } = await axios.post(
      `${config.api}/user/getpermissions`,
    );

    user.permissions = permissionData;

    try {
      const { data: photoData } = await axios.post(
        `${config.api}/timesheet/getLastPhoto`,
        {
          username_id: user.username_id,
        },
      );

      user.permissions = permissionData;

      dispatch({
        type: Action.LOGIN,
        payload: {
          user,
          token: token,
        },
      });
    } catch (err) {
      
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Action.LOGOUT });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post("/api/authentication/register", {
      email,
      name,
      password,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("authToken", accessToken);
    dispatch({
      type: Action.REGISTER,
      payload: {
        user,
        token: accessToken,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        twoFactorLogin,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
