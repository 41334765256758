import React, { createContext, useEffect, useState, useCallback } from "react";
import WebSocket from "../api/web_socket";
import config from "../consts";
import PropTypes from "prop-types";

import useAPI from "../hooks/useAPI";

const WebSocketContext = createContext({
  entities: [],
  chats: [],
  refreshChats: () => Promise.resolve(),
  socket: null,
  addMessage: () => Promise.resolve(),
  loadMessages: () => Promise.resolve(),
});

export const WebSocketProvider = (props) => {
  const { children } = props;
  const [socket, set_socket] = useState();

  const [entities, set_entities] = useState([]);
  const [chats, set_chats] = useState([]);
  const api = useAPI();

  const getSocket = async () => {
    const newEntities = await api.entities().get_all();

    if (Array.isArray(newEntities)) {
      set_entities([...newEntities]);
    }

    let newChats = await api.chats().get_all();
    if (Array.isArray(newChats)) {
      for (let chat of newChats) {
        let messages = await getChatHistory(chat.id);

        chat.messages = messages;

        if (chat.messages.length < 40) chat.at_top = true;
      }

      set_chats([...newChats]);
    }

    const token = window.localStorage.getItem("authToken");

    let socket = new WebSocket(
      `wss://${config.api.replace(/^https?\:\/\//, "")}/ws`,
      token
    );

    set_socket(socket);
  };

  useEffect(() => {
    if (socket) {
      const [entity] = entities;

      let unsubscribe = socket.subscribe("message", {}, (message) => {
        const { chat_id } = message;
        let newMessage = {
          from: message?.user_id || message?.entity_id,
          type: message?.type,
          entity_id: message?.entity_id,
          message: message.content,
          content: message.content,
          time: message.time,
          id: message.id,
        };

        addMessage(chat_id, newMessage);
      });
      return () => unsubscribe();
    }
  }, [socket]);

  const refreshChats = async () => {
    let newChats = await api.chats().get_all();

    for (let chat of newChats) {
      let messages = await getChatHistory(chat.id);

      chat.messages = messages;
    }

    set_chats([...newChats]);
  };

  useEffect(() => {
    getSocket();
  }, []);

  const addMessage = useCallback(
    async (chat_id, newMessage) => {
      try {
        const prevChats = [...chats];
        const index = prevChats.findIndex((chat) => chat.id === chat_id);

        prevChats[index].messages.push(newMessage);

        set_chats([...prevChats]);
      } catch (err) {}
    },
    [chats]
  );

  // Load Messages Here which takes a chat_id and does the same processing as the GET SOCKET

  const loadMessages = async (chat_id) => {
    let chat_i = chats.findIndex((c) => c.id === chat_id);
    let chat = { ...chats[chat_i] };

    let newHistory = await api.chats(chat_id).get_history({
      last_message: chat.messages ? chat.messages[0].id : undefined,
      limit: 40,
    });

    if (newHistory.length < 40) chat.at_top = true;
    chat.messages = [...newHistory.reverse(), ...chat.messages];

    set_chats([
      ...chats.slice(0, chat_i),
      chat,
      ...chats.slice(chat_i + 1, chats.length),
    ]);
  };

  const getChatHistory = async (chat_id) => {
    let chat_i = chats.findIndex((c) => c.id === chat_id);
    let chat = { ...chats[chat_i] };

    let newHistory = await api.chats(chat_id).get_history({
      last_message: chat.messages
        ? chat.messages[chat.messages.length - 1]
        : undefined,
      limit: 40,
    });

    if (!Array.isArray(chat.messages)) chat.messages = [];

    chat.messages = [...chat.messages, ...newHistory];

    newHistory = newHistory.reverse().map((message) => {
      return {
        from: message?.user_id || message?.entity_id,
        type: message?.type,
        entity_id: message?.entity_id,
        message: message.content,
        content: message.content,
        time: message.time,
        id: message.id,
      };
    });
    return newHistory;
  };

  let value = {
    socket,
    entities,
    chats,
    refreshChats,
    addMessage,
    loadMessages,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

WebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const WebSocketConsumer = WebSocketContext.Consumer;

export default WebSocketContext;
