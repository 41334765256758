import React, { Component } from "react";
import { Preloader, Placeholder } from "react-preloading-screen";

import Login from "../components/login/login";

import { ThemeProvider } from "@mui/material/styles";
import { Container, Box } from "@mui/material";
import Logo from "../components/Logo";

const LoginPage = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          width: `100%`,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Logo />
        <Login />
      </Box>
    </Container>
  );
};

export default LoginPage;
