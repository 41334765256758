import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

const DigitInput = React.forwardRef(({ value, onChange, ...props }, ref) => (
  <input
    {...props}
    ref={ref}
    value={value}
    onChange={onChange}
    type="text"
    maxLength="1"
    style={{
      width: "32px",
      height: "32px",
      textAlign: "center",
      margin: "0 4px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      outline: "none",
      fontSize: "16px",
    }}
  />
));

const TOTPEntryDialog = ({ open, onClose, onSubmit, loading, error }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [inputRefs, setInputRefs] = useState([]);

  useEffect(() => {
    setInputRefs((refs) =>
      Array(6)
        .fill()
        .map((_, i) => refs[i] || React.createRef()),
    );
  }, []);

  useEffect(() => {
    otp.forEach((digit, index) => {
      if (digit.length === 1 && inputRefs[index + 1]) {
        inputRefs[index + 1].current.focus();
      }
    });
    if (otp[0].length === 0 && inputRefs[0] && inputRefs[0].current) {
      inputRefs[0].current.focus();
    }
  }, [otp, inputRefs]);

  useEffect(() => {
    if (open && inputRefs[0] && inputRefs[0].current) {
      inputRefs[0].current.focus();
    }
  }, [open, inputRefs]);

  const handleChange = (index) => (event) => {
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const handleKeyPress = (index) => (event) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      handleChange(index - 1)({ target: { value: "" } });
    }
  };

  const handleSubmit = () => {
    if (otp.every((digit) => digit.length === 1)) {
      onSubmit(otp.join(""));
      setOtp(Array(6).fill(""));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enter 6-Digit Code</DialogTitle>
      <DialogContent>
        {!loading && (
          <Box display="flex" justifyContent="center" mb={2}>
            {otp.map((digit, index) => (
              <DigitInput
                key={index}
                ref={inputRefs[index]}
                value={digit}
                onChange={handleChange(index)}
                onKeyDown={handleKeyPress(index)}
              />
            ))}
          </Box>
        )}
        {error && (
          <Typography color="error" variant="body2" align="center">
            {error}
          </Typography>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={loading || otp.some((digit) => digit.length !== 1)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TOTPEntryDialog;
