const MESSAGE_TYPES = [
  "auth",
  "request",
  "response",
  "error",
  "event",
  "subscribe",
];

function random_id() {
  return `${Date.now().toString(16)}${Math.round(
    Math.random() * Math.pow(10, 10)
  ).toString(16)}`;
}

export default class Socket {
  constructor(url, token) {
    this.requests = {};
    this.subscriptions = {};
    this.closed = false;
    this.url = url;
    this.token = token;
    this.reconnecting = false;

    this.socket = new WebSocket(url);
    this.initialise_socket();
  }

  initialise_socket() {
    this.ready = new Promise((resolve) => {
      this.socket.addEventListener("open", () => {
        // Perform authentication
        this.send({
          endpoint: "",
          data: { token: this.token },
          type: "auth",
          skip_ready: true,
        }).then(() => {
          console.log("Successfully authenticated");

          resolve();
        });
      });
    });

    this.socket.addEventListener("message", (e) => {
      let payload;

      try {
        // Parse as JSON
        let data = e.data.toString("utf8");
        payload = JSON.parse(data);

        // Ensure all the fields exist
        if (
          !(
            MESSAGE_TYPES.includes(payload.type) &&
            typeof payload.id === "string" &&
            typeof payload.data === "object"
          )
        ) {
          throw new Error("Invalid fields");
        }
      } catch (e) {
        console.error("Problem recieving message from web socket:", e);
      }

      if (payload) {
        if (["response", "error"].includes(payload.type)) {
          if (this.requests[payload.id]) {
            let { resolve, reject } = this.requests[payload.id];

            if (payload.type === "response") resolve(payload.data);
            else reject(payload.data);
          } else {
            console.error("Unknown message id");
          }
        } else if (payload.type === "request") {
          // TODO: Handle requests here
        } else if (payload.type === "event") {
          if (this.subscriptions[payload.id] !== undefined)
            this.subscriptions[payload.id].callback(payload.data);
        }
      }
    });

    this.socket.addEventListener("error", (e) => {
      // Handle error
    });

    this.socket.addEventListener("close", (e) => {
      // Handle close
      console.log("Closing socket, attempting to reopen");
      this.reconnecting = true;

      setTimeout(() => {
        this.socket = new WebSocket(this.url);
        this.initialise_socket();

        this.ready.then(() => {
          console.log("Successfully reconnected");
          this.reconnecting = false;

          // Resubscibe to everything
          let subscriptions = { ...this.subscriptions };
          this.subscriptions = {};
          for (let subscription of Object.values(subscriptions)) {
            this.subscribe(
              subscription.event,
              subscription.params,
              subscription.callback
            );
          }
        });
      }, 1000);
      // this.closed = true;
    });
  }

  subscribe(event, params, callback) {
    let id_promise = this.send({
      endpoint: "",
      data: {
        event,
        params,
      },
      type: "subscribe",
    }).then(({ id }) => {
      this.subscriptions[id] = { event, params, callback };
      return id;
    });

    return () => {
      id_promise.then((id) => {
        this.send({
          endpoint: "",
          data: {
            event: this.subscriptions[id].event,
            id,
          },
          type: "unsubscribe",
        });

        delete this.subscriptions[id];
      });
    };
  }

  send({ endpoint, data, type = "request", skip_ready = false }) {
    return new Promise(async (resolve, reject) => {
      if (typeof endpoint !== "string" || data === undefined) reject();
      else {
        if (!skip_ready) await this.ready;

        if (!this.closed) {
          // Generate an ID for the request
          let id = random_id();

          this.requests[id] = { resolve, reject };

          this.socket.send(
            JSON.stringify({
              type,
              endpoint,
              data,
              id,
            })
          );
        } else reject({ message: "Socket closed" });
      }
    });
  }
}
