import config from "../consts";

var token = null;

async function request(
  endpoint,
  { method = "get", body, query, base = "/hiring", additionalHeaders = {} } = {}
) {
  if (!token) {
    token = await window.localStorage.getItem("authToken");
  }

  let url = new URL(`/api${base}${endpoint}`, config.api);

  let headers = {
    Authorization: `Bearer ${token}`,
  };

  if (typeof query === "object") {
    let params = new URLSearchParams(query);
    url.search = params.toString();
  }

  if (method.toLowerCase() === "get" || method.toLowerCase() === "head")
    body = undefined;
  else if (body !== undefined && !(body instanceof FormData)) {
    body = JSON.stringify(body);
    headers["Content-Type"] = "application/json";
  }

  if (additionalHeaders) {
    headers = { ...headers, ...additionalHeaders };
  }

  return fetch(url, {
    method,
    headers,
    body,
  }).then((res) => res.json());
}

export default {
  listing: (param) => {
    if (typeof param === "number") {
      let listingid = param;

      return {
        get: ({ lat = 0, lon = 0 }) => {
          // Return a request for a listing of id param
          return request(
            `/listings/listing?id=${listingid}&lat=${lat}&lon=${lon}`,
            {
              query: { id: listingid },
            }
          );
        },
        availability: ({ startDate, endDate }) => {
          return request(
            `/listings/availability?id=${listingid}&startDate=${startDate}&endDate=${endDate}`
          );
        },
        add_vehicles: ({ vehicles }) => {
          return request(`/listings/vehicles`, {
            method: "post",
            body: { id: listingid, vehicles },
          });
        },
        delete: () => {
          //Needs to be updated before we can support deleting for regular users
          return request(`/listings/listing?id=${listingid}`, {});
        },
      };
    } else if (typeof param === "undefined") {
      return {
        get: (ids, lat = 0, lon = 0) => {
          // Return request for all shipments for courier
          return request(`/listings/listings?ids=${ids}&lat=${lat}&lon=${lon}`);
        },
        get_company_listings: (companyid, lat, lon) => {
          return request(
            `/search/company?id=${companyid}&lat=${lat}&lon=${lon}`
          );
        },
        create: ({
          dailyRate,
          lat,
          lon,
          title,
          description,
          minHirePeriod,
          maxDeliveryRange,
          terms,
          availableAddons,
          tickets,
          photos,
        }) => {
          return request("/listings/listing", {
            method: "post",
            body: {
              dailyRate,
              lat,
              lon,
              title,
              description,
              minHirePeriod,
              maxDeliveryRange,
              terms,
              availableAddons,
              tickets,
              photos,
            },
          });
        },
        patch: ({
          id,
          dailyRate,
          lat,
          lon,
          title,
          description,
          minHirePeriod,
          maxDeliveryRange,
          terms,
          availableAddons,
          tickets,
          photos,
        }) => {
          return request("/listings/listing", {
            method: "patch",
            body: {
              id,
              dailyRate,
              lat,
              lon,
              title,
              description,
              minHirePeriod,
              maxDeliveryRange,
              terms,
              availableAddons,
              tickets,
              photos,
            },
          });
        },
        search: ({
          features,
          limit,
          offset,
          startDate,
          endDate,
          lat,
          lon,
          radius,
        }) => {
          return request("/search/search", {
            method: "post",
            body: {
              features,
              limit,
              offset,
              startDate,
              endDate,
              lat,
              lon,
              radius,
            },
          });
        },
      };
    }
  },
  profile: (param) => {
    if (typeof param === "number") {
      let companyid = param;

      return {
        get: () => {
          return request(`/profile/company?id=${companyid}`);
        },
      };
    } else if (typeof param === "undefined") {
      return {
        create: ({
          profile_picture,
          background_picture,
          colors,
          lat,
          lon,
          bio,
        }) => {
          return request("/profile/company", {
            method: "post",
            body: {
              profile_picture,
              background_picture,
              colors,
              lat,
              lon,
              bio,
            },
          });
        },
        update: ({
          profile_picture,
          background_picture,
          colors,
          lat,
          lon,
          bio,
          name,
        }) => {
          const newObject = {};
          if (profile_picture !== undefined)
            newObject.profile_picture = profile_picture;
          if (background_picture !== undefined)
            newObject.background_picture = background_picture;
          if (name !== undefined) newObject.nickname = name;
          if (colors !== undefined) newObject.colors = colors;
          if (lat !== undefined) newObject.lat = lat;
          if (lon !== undefined) newObject.lon = lon;
          if (bio !== undefined) newObject.bio = bio;
          if (colors !== undefined) newObject.colors = colors;

          return request("/profile/company", {
            method: "post",
            body: newObject,
          });
        },
      };
    }
  },
  hires: (param) => {
    let hireid = param;

    if (typeof param === "number") {
      return {
        get: () => {},
        deny: () => {
          return request("/hire/deny", {
            method: "post",
            body: {
              id: hireid,
            },
          });
        },
        approve: () => {
          return request("/hire/approve", {
            method: "post",
            body: {
              id: hireid,
            },
          });
        },
        update: () => {},
        start: () => {},
        end: () => {},
        extend: () => {},
      };
    } else if (typeof param === "undefined") {
      return {
        get_all: ({ uuid, scheduledstart }) => {
          return request(
            `/hire/hires?uuid=${uuid}&scheduledstart=${scheduledstart}`
          );
        },
        user_upcoming: () => {
          return request("/hire/userUpcoming");
        },
        company_upcoming: () => {
          return request("/hire/companyUpcoming");
        },
        company_all: ({ offset, limit, status }) => {
          return request(
            `/hire/companyAll?status=${status}${
              limit ? `&limit=${limit}` : ""
            }${offset ? `&offset=${offset}` : ""}`
          );
        },
        create: () => {},
        request: ({ listingId, addons, startDate, endDate, deliveryPoint }) => {
          return request("/hire/request", {
            method: "post",
            body: {
              listingId,
              addons,
              startDate,
              endDate,
              deliveryPoint,
            },
          });
        },
      };
    }
  },
  features: (param) => {
    if (typeof param === "number") {
    } else if (typeof param === "undefined") {
      return {
        get_all: () => {
          return request("/search/allFeatures");
        },
        create: ({ listingId, features }) => {
          return request("/search/features", {
            method: "post",
            body: {
              listingId,
              features,
            },
          });
        },
      };
    }
  },
  vehicles: (param) => {
    if (typeof param === "number") {
    } else if (typeof param === "undefined") {
      return {
        create: ({
          registration,
          odometer,
          height,
          width,
          length,
          tickets,
          notes,
        }) => {
          return request("/vehicles/vehicle", {
            method: "post",
            body: {
              registration,
              odometer,
              height,
              width,
              length,
              tickets,
              notes,
            },
          });
        },
      };
    }
  },
  geo: () => {
    return {
      locality_search: ({ query, offset, limit }) => {
        return request(
          `/geo/locality_search?query=${query}&offset=${offset}&limit=${limit}`,
          {
            base: "/v2",
            query: {
              query,
              offset,
              limit,
            },
          }
        );
      },
      reverse_geolocate: ({ latitude, longitude }) => {
        return request(`/address?latitude=${latitude}&longitude=${longitude}`, {
          base: "/address",
        });
      },

      reverse_locality_search: ({ latitude, longitude }) => {
        return request(
          `/geo/reverse_locality_search?latitude=${latitude}&longitude=${longitude}`,
          {
            base: "/v2",
          }
        );
      },
    };
  },
  entities: () => {
    return {
      get_all: () => {
        return request(`/messaging/entities`, {
          base: "/v2",
        });
      },
    };
  },
  chats: (param) => {
    const chat_id = param;
    if (typeof param === "number") {
      return {
        get_history: ({ last_message = null, limit = 100 }) => {
          let params = [];
          if (last_message !== undefined)
            params.push(`last_message=${last_message}`);
          if (limit !== undefined) params.push(`limit=${limit}`);
          return request(`/messaging/chat/${chat_id}?${params.join("&")}`, {
            base: "/v2",
          });
        },
      };
    } else {
      return {
        get_all: () => {
          return request(`/messaging/chats`, {
            base: "/v2",
          });
        },
        general_chat: ({ company_id }) => {
          return request(`/messaging/chat/general_chat`, {
            base: "/v2",
            method: "post",
            body: { company_id },
          });
        },
        support_chat: () => {
          return request(`/messaging/chat/support_chat`, {
            base: "/v2",
            method: "post",
          });
        },
        listing_chat: ({ listing_id }) => {
          return request(`/messaging/chat/listing_chat`, {
            base: "/v2",
            method: "post",
            body: { listing_id },
          });
        },
      };
    }
  },
  utility: (param) => {
    const id = param;
    if (id !== undefined) {
      return {
        delete_photo: () => {},
      };
    } else {
      return {
        upload_photo: ({ formData }) => {
          return request(`/utility/upload`, {
            method: "post",
            base: "",
            body: formData,
            /* additionalHeaders: {
              "Content-Type": "multipart/form-data",
            },*/
          });
        },
      };
    }
  },
  reviews: (param) => {
    const review_id = param;
    if (review_id !== undefined) {
      // Defined
      return {};
    } else {
      // Not Defined
      return {
        get_all: ({ company_id, user_id, limit, offset = 0 }) => {
          let params = [];
          if (company_id !== undefined)
            params.push(`&company_id=${company_id}`);
          if (user_id !== undefined) params.push(`user_id=${user_id}`);
          if (limit !== undefined) params.push(`limit=${limit}`);
          if (offset !== undefined) params.push(`offset=${offset}`);
          return request(`/reviews/reviews?${params.join("&")}`);
        },
        create: ({ user_id, company_id, rating, reviewText }) => {
          return request(`/reviews/new`, {
            method: "post",
            body: { user_id, company_id, rating, reviewText },
          });
        },
      };
    }
  },
};
