import { createSlice } from "@reduxjs/toolkit";
//import axios from "../lib/axios";
import axios from "axios";
import config from "../consts";

const initialState = {
  user: {},
  permissions: null,
  avatar: null,
  groups: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUser(state, action) {
      state.user = { ...action.payload };
    },
    getPermissions(state, action) {
      state.permissions = { ...action.payload };
    },
    getAvatar(state, action) {
      state.avatar = action.payload;
    },
    getGroups(state, action) {
      state.groups = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getUser = () => async (dispatch) => {
  let { data } = await axios.get(`${config.api}/user/profile`);

  dispatch(slice.actions.getUser(data));
};

export const getGroups = () => async (dispatch) => {
  let { data, status } = await axios.post(
    `${config.api}/user/getcompgroupsuser`
  );

  dispatch(slice.actions.getGroups(data));
};

export const getPermissions = () => async (dispatch) => {
  const { data: permissionData } = await axios.post(
    `${config.api}/user/getpermissions`
  );
  let permissions = permissionData;

  dispatch(slice.actions.getPermissions(permissions));
};

export const getAvatar = (username_id) => async (dispatch) => {
  const { data: photoData } = await axios.post(
    `${config.api}/timesheet/getLastPhoto`,
    {
      username_id,
    }
  );

  let avatar = photoData[0].photo;

  dispatch(slice.actions.getAvatar(avatar));
};

export default slice;
