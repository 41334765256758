import React, { useState } from "react";
import { Alert, Container, Grid, TextField, Button } from "@mui/material";
import axios from "axios";
import config from "../consts";
import { useNavigate } from "react-router-dom-v5-compat";

const RecoverPassword = () => {
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    severity: "info",
    message: "",
  });

  const navigate = useNavigate();

  const resetPassword = () => {
    // Get the token from the query string
    let params = window.location.search
      .replace(/^\?/, "")
      .split("&")
      .reduce((obj, param) => {
        param = param.split("=");

        obj[param[0]] = param[1];

        return obj;
      }, {});

    let token = params["token?"];

    if (token === undefined) {
      setAlert({
        show: true,
        severity: "error",
        message: "No login token can be found",
      });
      return;
    }

    // Make the request to login with the token
    setAlert({
      show: true,
      severity: "info",
      message: "Logging in with token...",
    });

    axios
      .get(`${config.api}/auth/tokenLogin`, {
        params: {
          token,
        },
      })
      .then((res) => {
        let user = res.data;

        if (user.token) {
          localStorage.authToken = user.token;
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + user.token;

          setAlert({
            show: true,
            severity: "success",
            message: "Successfully logged in, changing password",
          });

          // Reset the password
          axios
            .post(`${config.api}/accounts/resetPassword`, {
              password: password,
            })
            .then(() => {
              setAlert({
                show: true,
                severity: "success",
                message: "Successfully changed password! Redirecting...",
              });

              navigate("/");
            });
        } else {
          setAlert({
            show: true,
            severity: "error",
            message: "Something went wrong whilst trying to log in",
          });
        }
      })
      .catch(() =>
        setAlert({
          show: true,
          severity: "error",
          message: "Failed to log in using token, it might've expired",
        }),
      );
  };

  return (
    <Container maxWidth="sm">
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <h1>Recover account</h1>
        </Grid>
        <Grid item>
          {/* Required, otherwise 'undefined' will be printed in the password box */}
          <TextField hidden type="password" />

          <TextField
            label="New Password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={resetPassword}
            disabled={password.length === 0}
          >
            Reset Password
          </Button>
        </Grid>
        <Grid item>
          <Alert severity={alert.severity} hidden={!alert.show}>
            {alert.message}
          </Alert>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RecoverPassword;
