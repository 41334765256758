import React, { useEffect } from "react";
import { Preloader, Placeholder } from "react-preloading-screen";
import axios from "axios";

import { Container, Typography } from "@mui/material";

import config from "../consts";

const LoginPage = () => {
  const callInactive = async () => {
    const { data } = axios.get(`${config.api}/v2/users/inactive_login`);
  };

  useEffect(() => {
    callInactive();
  }, []);
  return (
    <Container maxWidth="xl">
      <Typography sx={{ mt: 2 }} variant="h6">
        Your account is deactivated. Please contact an admin.
      </Typography>
    </Container>
  );
};

export default LoginPage;
